import React from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import MenBehindTheBlade from '../components/MenBehindTheBlade';

const MenBehindTheBlades = () => {
    return (
        <>
            <Hero />
            <MenBehindTheBlade />
            <Footer />
        </>

    )
}

export default MenBehindTheBlades;