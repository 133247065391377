import React from 'react';
import img from '../images/sangay-chophel.jpg';
import img1 from '../images/ugyen-yeshi-dorji.webp'

const MenBehindTheBlade = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img alt="card img" className="rounded-t float-right" src={img} />
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <h3 className="text-3xl  text-blue-900 font-bold">Sangay Chophel</h3>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>We'd like you to meet our Project Manager, a committed and experienced leader within our team. With a proven track record in steering projects to success, they are a driving force behind our project excellence. Their dedication, attention to detail, and strong work ethic ensure that every project is on the path to success. Get to know the seasoned professional who's here to make your projects thrive.</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <h3 className="text-3xl  text-blue-900 font-bold">Ugyen Yeshi Dorji</h3>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>Meet the mastermind behind our marketing efforts at the Blacksmithing Workshop. With a unique blend of creative prowess and a sharp sense of humor, they've seamlessly integrated into our team, breathing life into our brand. Their creative magic and passion for blacksmithing are the driving force behind our workshop's success. Get to know the creative genius who's forging our brand's path to greatness.</p>
                        </div>
                    </div>
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img alt="card img" className="rounded-t float-right" src={img1} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MenBehindTheBlade;