import React from 'react';
import img from '../images/knife3.jpg';

const Intro = () => {
    return (
        <>
            <div className="m-auto" id='about' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="flex flex-col lg:mx-4 justify-center">
                        <img alt="card img" className="rounded-t float-right" src={img} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;